import $ from "jquery";
document.addEventListener("DOMContentLoaded", function() {
  $("body").on("click", "#lnk-add-code-promo", function() {
    $("#lnk-form-code-promo .promo-input").val($(this).data("code-promo"));
    $("#lnk-form-code-promo").submit();
    $(this).closest(".alert").html("Vous avez ajout\xE9 le code de r\xE9duction au panier");
  });
  $(".homeslider__slide").each(function() {
    let desc = $(this).find(".homeslider__desc .slide-subtitle");
    var text = desc.text().toLowerCase().replace("re.sponsable", '<span class="responsable-wrapper"><span class="re-yellow"></span>sponsable</span>');
    desc.html(text);
  });
  $("#slide_home_20").css({
    "width": "inherit",
    "height": "68%",
    "cursor": "pointer",
    "opacity": "0"
  });
  $("#slide_home_20").click(function(e) {
    e.preventDefault();
    console.log($(".bfv-gifts-home").offset().top);
    console.log("i'm clicking here");
    $("html,body").animate({
      scrollTop: $(".bfv-gifts-home").offset().top - 300
    }, "slow");
  });
});
if (module.hot) {
  module.hot.accept();
}
